import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import Rectangle from './Rectangle.jsx';
import Border from './Border.jsx';

function LockedField(props) {
  return (
    <div className={classNames({ 'hidden': !props.show }, 'action pos-rel')}>
      <Rectangle color="bg-ochre">
        <label className="f1 lh-solid fl ml4 sr-label" htmlFor={'checkLocked' + props.eventId}>
          {props.i18n('web.ticketselection.unlockCode.placeholder')}</label>
        <input data-test="unlockcode" name={'checkLocked' + props.eventId} id={'checkLocked' + props.eventId} type="text" className="f2 pam promotion-code fl"
          placeholder={props.i18n('web.ticketselection.unlockCode.placeholder')}/>
        <div className={`fr circle click-area btn-adjust ${props.hasLockedTicket ? 'lock-open' : 'lock-closed'} bg-white`} onClick={props.checkCode}>
        </div>
        <Border color={props.bottomBorderColor} show={props.showBottomBorder} top={'0.2rem'} gap={12}/>
      </Rectangle>
    </div>
  );
}

LockedField.propTypes = {
  eventId: PropTypes.number,
  show: PropTypes.bool,
  checkCode: PropTypes.func,
  showBottomBorder: PropTypes.bool,
  bottomBorderColor: PropTypes.string,
  hasLockedTicket: PropTypes.bool,
  i18n: PropTypes.func,
};

LockedField.defaultProps = {
  borderColor: '#CCCCCC',
  showBottomBorder: false,
};

export default LockedField;
